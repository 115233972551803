import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Waterbury Car Accident Lawyer",
    heroH1: "Waterbury Car Accident Lawyer",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Hurt in a car accident in Waterbury?</h2>
            <p className="mb-8">
              Then you need an experienced Waterbury car accident lawyer. We are
              here to help you and your family through the legal process and to
              help maximize your compensation for your injuries. Another aspect
              of our job is to reduce your anxiety and fear of the legal
              process. We will be with you every step of the way on your road to
              recovery.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
          <div className="mb-16">
            <h2>Who we are and why you should choose our firm.</h2>
            <p className="mb-8">
              Bartlett Legal Group is nationally recognized and proud to be a
              top-ranked personal injury firm. We are trial lawyers with our
              main focus on wrongful death, and serious car, truck, and
              motorcycle accident cases. Our lead car accident lawyer Frank
              Bartlett has an exceptional background to help maximize your
              results. Before opening this firm he was a trial attorney for
              several years representing insurance companies as defendants which
              provided him with invaluable insights into how the insurance
              companies value cases. Since opening Bartlett Legal Group, LLC
              they only represent the plaintiffs or those injured. Choosing an
              experienced attorney with a proven track record can make a big
              difference in the outcome of your case and the amount of
              compensation you may receive.
            </p>
            <div className="mb-8 text-center">
              <StaticImage
                quality="100"
                src="../../images/top-attorney-car-accident.png"
                formats={["auto", "webp", "avif"]}
                alt="Rated 10 for top car accident attorney"
              />
            </div>
            <p className="mb-8">
              <Link to="/car-accident-lawyer/">
                Click here for the answers to important questions after a New
                Haven car accident. This information may change the outcome of
                your case.
              </Link>
            </p>
          </div>
          <div className="mb-16">
            <h2>
              Were you in an accident with a tractor-trailer truck? Call our
              Waterbury truck accident lawyer now.
            </h2>
            <p className="mb-8">
              This is a complex type of motor vehicle accident case. There are
              many causes and factors that we need to investigate for you as
              soon as possible:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8">
              <li>Rollovers</li>
              <li>Drunk, intoxicated, or DUI truck drivers</li>
              <li>Overloaded vehicles</li>
              <li>Sleep deprived drivers</li>
              <li>Distracted drivers such as texting while driving</li>
              <li>Unsafe trucks</li>
              <li>
                Trucks that have not corrected issues found during inspections
              </li>
            </ul>
            <p className="mb-8">
              One of Attorney Bartlett’s first cases over fourteen years ago
              involved a tractor-trailer accident. Because of Attorney
              Bartlett’s hands-on experience with the total restoration of
              vehicles as a hobby, he was able to ask the needed technical
              questions about the construction and maintenance of the braking
              system on the truck. These questions changed the outcome of the
              case. You not only need someone that knows the law, but you also
              need someone that can challenge the other side’s experts.
            </p>
          </div>
          <div className="mb-16">
            <h2>Were you involved in a motorcycle accident in Waterbury?</h2>
            <p className="mb-8">
              We are motorcycle riders and know first hand the dangers of
              riding. Our Waterbury motorcycle accident attorney can help you
              through this difficult time.
            </p>
            <h3>
              You are not alone. Here are the statistics for Waterbury crashes
              for 2015
            </h3>
            <p className="mb-8">
              There were a total of 5182 crashes, 16 crashes involving
              fatalities, 19 people were killed. There were 1333 crashes with
              injuries and 1926 injuries. Information provided by the CT crash
              facts book.
            </p>
          </div>
          <div className="mb-16">
            <h2>Useful local Waterbury information and links.</h2>
            <p className="mb-8">
              If my case doesn’t settle and we have to take it to court, where
              is the court located? Because of our experience, reputation, and
              because we prepare every case as if it will go to trial, the
              insurance companies often offer us a fair settlement. It is your
              ultimate decision whether you accept a possible offer to settle.
              We will discuss your options along with upside potentials and
              downside risks associated with going to court. If you decide you
              want to go to court, most Waterbury car accident cases will be
              heard at the Courthouse at 300 Grand St. in Waterbury.
            </p>
            <div className="mb-8">
              <StaticImage
                quality="100"
                src="../../images/Waterbury-Accident-Courthouse-.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Photo of Waterbury Courthouse"
              />
            </div>
            <p className="mb-8">
              <strong>
                Waterbury Court Hours are 9-5 but doors open at 8:30.
              </strong>
            </p>
            <p className="mb-8">
              Parking for the Waterbury Court is available at the municipal
              parking garage which is located at the intersection of Bank and
              Grand Streets. Limited first come first served free public parking
              is also available in the courthouse ramp garage, located on State
              Street, next to 400 Grand Street. Metered parking is available on
              Grand Street.
            </p>
            <p className="mb-8">
              For additional information such as directions, phone numbers and
              more for the Waterbury courthouse{" "}
              <a
                href="https://www.jud.ct.gov/directory/directory/directions/35.htm"
                target="_blank"
                rel="noreferrer"
              >
                please visit the State of Connecticut Waterbury Judicial Branch
                website
              </a>
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
            <h3>Waterbury Weather</h3>
            <p className="mb-8">This seems obvious, but it’s extremely important to be on time when going to court.  Over the years we’ve heard many excuses why clients were late.  Know the Waterbury weather for the next day the night before going to court.</p>
            <h3>Where to eat near the Waterbury courthouse.</h3>
            <p className="mb-8"><a href="https://www.tripadvisor.com/RestaurantsNear-g33963-d85921-Courtyard_by_Marriott_Waterbury_Downtown-Waterbury_Connecticut.html" target="_blank" rel="noreferrer">Here’s a link from Tripadvisor on the ten best places to eat near the courthouse.</a></p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
